<template>
  <div>
    <van-field input-align="center" label="开始时间" readonly v-model="beginDate" @click="selectBeginDate" is-link></van-field>
    <van-field input-align="center" label="结束时间" readonly v-model="endDate" @click="selectEndDate" is-link></van-field>
    <el-row>
      <el-col :span="24">
        <div style="padding:0px 16px 2px 16px">
          <van-button block type="info" icon="search" @click="search">查询</van-button>
        </div>
      </el-col>
    </el-row>
    <van-tabs type="card">
      <van-tab title="图形">
        <div id="WXCW01Chart" style="padding:1px 16px 0px 16px"></div> <!-- 上右下左 -->
      </van-tab>
      <van-tab title="明细">
        <div :style="'height:'+listHeight+'px;overflow:scroll;'">
          <van-list v-model="listLoading" :finished="listFinished" @load="listLoad" finished-text="没有更多了" :immediate-check="false">
            <el-card v-for="(item,index) in dataList" :key="index" style="margin:10px" :body-style="{ padding: '0px' }">
              <van-cell :title="'时间'" :value="item.dataTime">
              </van-cell>
              <el-row type="flex" justify="space-between" class="sumjvanrow">
                <el-col :span="2">T1:</el-col>
                <el-col :span="4">{{item.t1==2500?'-':item.t1/10.0+'℃'}}</el-col>
                <el-col :span="2">T2:</el-col>
                <el-col :span="4">{{item.t2==2500?'-':item.t2/10.0+'℃'}}</el-col>
                <el-col :span="2">T3:</el-col>
                <el-col :span="4">{{item.t3==2500?'-':item.t3/10.0+'℃'}}</el-col>
                <el-col :span="2">T4:</el-col>
                <el-col :span="4">{{item.t4==2500?'-':item.t4/10.0+'℃'}}</el-col>
              </el-row>
              <van-row type="flex" justify="space-between" class="sumjvanrow">
                <van-col :span="2">T5:</van-col>
                <van-col :span="4">{{item.t5==2500?'-':item.t5/10.0+'℃'}}</van-col>
                <van-col :span="2">T6:</van-col>
                <van-col :span="4">{{item.t6==2500?'-':item.t6/10.0+'℃'}}</van-col>
                <van-col :span="2">T7:</van-col>
                <van-col :span="4">{{item.t7==2500?'-':item.t7/10.0+'℃'}}</van-col>
                <van-col :span="2">T8:</van-col>
                <van-col :span="4">{{item.t8==2500?'-':item.t8/10.0+'℃'}}</van-col>
              </van-row>
            </el-card>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup v-model="popupBeginDateVisible" position="bottom">
      <van-datetime-picker type="date" v-model="popupBeginDate" title="选择年月日" @cancel="popupBeginDateVisible=false" @confirm="confirmBeginDate"></van-datetime-picker>
    </van-popup>
    <van-popup v-model="popupEndDateVisible" position="bottom">
      <van-datetime-picker type="date" v-model="popupEndDate" title="选择年月日" @cancel="popupEndDateVisible=false" @confirm="confirmEndDate"></van-datetime-picker>
    </van-popup>
  </div>
</template>
<script>
import Vue from "vue";
import Vant from "vant";
import Moment from "moment";
import HighCharts from "highcharts";
import HighchartsHanHua from "@/util/highcharts-lang-zh_CN.js";
import dark from "highcharts/themes/dark-unica";

dark(HighCharts);
HighchartsHanHua.HighchartsHanHua(HighCharts);
HighCharts.setOptions({ global: { useUTC: false } });
Vue.use(Vant);

export default {
  props: ["serialNumber"],
  data() {
    return {
      popupBeginDateVisible: false,
      popupBeginDate: new Date(),
      beginDate: new Moment().format("YYYY-MM-DD"),
      popupEndDateVisible: false,
      popupEndDate: new Date(),
      endDate: new Moment().format("YYYY-MM-DD"),
      listLoading: false,
      listFinished: false,
      dataList: [],
      dataListAll: [],
      currentIndex: 0, //当前加载到dataListAll的哪个Index
      listHeight: 0,
    };
  },
  methods: {
    selectBeginDate() {
      let that = this;
      that.popupBeginDate = new Moment(that.beginDate).toDate();
      that.popupBeginDateVisible = true;
    },
    confirmBeginDate(v) {
      let that = this;
      that.beginDate = new Moment(v).format("YYYY-MM-DD");
      that.popupBeginDateVisible = false;
    },
    selectEndDate() {
      let that = this;
      that.popupEndDate = new Moment(that.endDate).toDate();
      that.popupEndDateVisible = true;
    },
    confirmEndDate(v) {
      let that = this;
      that.endDate = new Moment(v).format("YYYY-MM-DD");
      that.popupEndDateVisible = false;
    },
    listLoad() {
      let that = this;
      for (var i = 1; i <= 10; i++) {
        if (that.currentIndex >= that.dataListAll.length) {
          that.listFinished = true;
          break;
        } else {
          that.dataList.push(that.dataListAll[that.currentIndex]);
          that.currentIndex++;
        }
      }
      that.listLoading = false;
    },
    search() {
      let that = this;
      if (Moment(that.endDate) - Moment(that.beginDate) > 3600000 * 24 * 90) {
        that.$toast("时间大于90天");
        return;
      }
      const tmpLoading = that.$loading();
      that.axios
        .post("WXCW01/GetList", {
          serialNumber: that.serialNumber,
          beginDate: that.beginDate,
          endDate: that.endDate,
        })
        .then(function (response) {
          that.listLoading = that.listFinished = false;
          that.currentIndex = 0;
          that.dataList = [];
          that.dataListAll = response.data.data;
          that.listLoad();
          that.loadChart();
          tmpLoading.close();
        });
    },
    loadChart() {
      let that = this;
      var mapData = [];
      mapData.push({ name: "T1", data: [] });
      mapData.push({ name: "T2", data: [] });
      mapData.push({ name: "T3", data: [] });
      mapData.push({ name: "T4", data: [] });
      mapData.push({ name: "T5", data: [] });
      mapData.push({ name: "T6", data: [] });
      mapData.push({ name: "T7", data: [] });
      mapData.push({ name: "T8", data: [] });
      for (var i = that.dataListAll.length - 1; i >= 0; i--) {
        if (that.dataListAll[i].t1 != 2500)
          mapData[0].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t1 / 10.0,
          ]);
        if (that.dataListAll[i].t2 != 2500)
          mapData[1].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t2 / 10.0,
          ]);
        if (that.dataListAll[i].t3 != 2500)
          mapData[2].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t3 / 10.0,
          ]);
        if (that.dataListAll[i].t4 != 2500)
          mapData[3].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t4 / 10.0,
          ]);
        if (that.dataListAll[i].t5 != 2500)
          mapData[4].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t5 / 10.0,
          ]);
        if (that.dataListAll[i].t6 != 2500)
          mapData[5].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t6 / 10.0,
          ]);
        if (that.dataListAll[i].t7 != 2500)
          mapData[6].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t7 / 10.0,
          ]);
        if (that.dataListAll[i].t8 != 2500)
          mapData[7].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t8 / 10.0,
          ]);
      }

      HighCharts.chart("WXCW01Chart", {
        tooltip: {
          formatter: function () {
            return (
              this.series.name +
              "<br />温度：" +
              this.y +
              "℃<br />时间：" +
              Moment(this.x).format("YYYY-MM-DD HH:mm:ss")
            );
          },
        },
        title: { text: "温度曲线图" },
        credits: { enabled: false },
        exporting: { enabled: false },
        //global: { useUTC: false },这里要在开头的时候初始化，而不是这里。
        yAxis: { title: { text: "温度" } },
        xAxis: { type: "datetime" },
        series: mapData,
      });
    },
  },
  mounted() {
    let that = this;
    document.title = that.serialNumber;
    that.listHeight = that.$parent.$el.offsetHeight - 182;
    that.search();
  },
};
</script>

<style scoped>
.sumjvanrow {
  color: #969799;
  font-size: 12px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
  line-height: 20px;
}
</style>